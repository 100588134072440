import React from 'react';

function SuccessModal({setFormData}) {
  return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pt-1 pl-2"></div>
                <div className="modal-body">
    				<div className="text-center">
                        <h5>Vaše rezervace byla přijata</h5>
                        <div id="modal-icon" className="text-success" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <i className="fas fa-check-circle fa-5x justify-center" aria-hidden="true"></i></div>
    					    <p className='fs-12'>Rezervaci Vám <strong>potvrdíme co nejdříve</strong>.</p>
                            <p className='fs-12'>Potvrzení Vám přijde e-mailem a SMS zprávou.</p>
    						<h5>Těšíme se na Vaši návštěvu.</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success w-50 mx-auto" onClick={()=>{setFormData({
                            name: '',
                            time: '',
                            guest: '2',
                            email: '',
                            phone: '',
                            comment: ''
                            })}}
                        data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
  );
}

export default SuccessModal;
