import React from 'react';

function ErrorModal({isErrorEmail, isErrorPhone, isErrorName, isErrorTime}) {
    return (
           <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header pt-1 pl-2"></div>
                    <div className="modal-body">
                        <div className="text-center">
                            <h5 className='text-danger'>Při odeslání objednávky došlo k chybě</h5>
                            <div id="modal-icon" className="text-danger mb-4 mt-4">
                                <i className="fas fa-exclamation-circle fa-5x justify-center" aria-hidden="true"></i>
                            </div>
                                <h5 className='text-danger'>{isErrorTime.state ? isErrorTime.message : ''}</h5>
                                <h5 className='text-danger'>{isErrorName.state ? isErrorName.message : ''}</h5>
                                <h5 className='text-danger'>{isErrorEmail.state ? isErrorEmail.message : ''}</h5>
                                <h5 className='text-danger'>{isErrorPhone.state ? isErrorPhone.message : ''}</h5>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger w-50 mx-auto" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
      );
}

export default ErrorModal;
