import React from 'react';

function Header() {
  return (
    <header>
    <nav id="main-navbar-top" className="bg-light navbar navbar-expand-md navbar-dark opaque-enabled fixed-top">
       <div className="container">
          <a className="navbar-brand" href="#">
              <img className="img-responsive shrink" src="https://eater.cz/uploads/ukazkovy-podnik/logo/1606760119.png" alt="" />
           </a>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
             <div className="navbar-nav" data-bs-toggle="modal" data-bs-target="#infoModal" style={{cursor: "pointer"}}>
                <span className="nav-item nav-link margin-head color-head-label"><i className="fas fa-map-marker-alt"></i> Eaterova třída  1, Praha</span>
                <span className="nav-item nav-link margin-head color-head-label"><i className="far fa-clock"></i> Dnes otevřeno do 20:00</span>
                <span className="nav-item nav-link margin-head color-head-label"><i className="fas fa-mobile-alt"></i> +420 723 198 559</span>
             </div>
          </div>
          
       </div>
    </nav>
 </header>
  );
}

export default Header;


   