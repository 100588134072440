import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SuccessModal from './modals/SuccessModal';
import ErrorModal from './modals/ErrorModal';
const urlGetTimes = 'http://localhost/api/getBasicOpeningTimes.php'
const urlGetDays = 'http://localhost/api/getOpeningDays.php'

function Main() {

    const [isSend, setIsSend] = useState(false)

    const [isErrorName, setIsErrorName] = useState({
        state: false,
        message: "Vyplňte prosím Vaše jméno"
    });

    const [isErrorEmail, setIsErrorEmail] = useState({
        state: false,
        message: "Vyplňte prosím Váš email"
    });

    const [isErrorPhone, setIsErrorPhone] = useState({
        state: false,
        message: "Vyplňte prosím Váš telefon"
    });

    const [isErrorTime, setIsErrorTime] = useState({
        state: false,
        message: "Vyplňte prosím čas rezervace"
    });



    const [time, setTime] = useState([])
    const [day, setDay] = useState([])
    const [currentDay, setCurrentDay] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        time: 'init',
        guest: '2',
        email: '',
        phone: '',
        comment: ''
        });

    const fetchTimes = async (url) => {
        try {
          const response = await fetch(url)
          const times = await response.json()
          setTime(times)
        } catch (error) {
          console.log(error)
        }
      }

      const fetchDays = async () => {
        try {
          const response = await fetch(urlGetDays)
          const days = await response.json()
          setDay(days)
          setFormData({ ...formData, date: days[0].date })
          fetchTimes(urlGetTimes+"?day="+days[0].date)
        } catch (error) {
          console.log(error)
        }
      }

      useEffect(() => {
        
        fetchDays()

        
      }, [])

      

      useEffect(() => {
            if(currentDay!==''){
            fetchTimes(urlGetTimes+'?day='+currentDay)
            console.log("den se změnil")
        }
      }, [currentDay])
    
    const configureDate =(e) =>{
        setCurrentDay(e.target.value)
        setFormData({ ...formData, date: e.target.value })
    }

    const validateEmail = (email)=>{
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(email.match(mailformat)){
            return true;
        }
        else {
            return false;
        }
    }

    const validateInput = (input, lengthValue)=>{
        if(input.length<lengthValue){
            return true;
        }
        else {
            return false;
        }
    }

    const validateForm = () =>{
        if(formData.time!=='init' && !validateInput(formData.phone, 9) && validateEmail(formData.email) && !validateInput(formData.name, 3)){
            setIsSend(true)
            return true
           
        } else {
            setIsSend(false)
            if (validateInput(formData.phone, 9)) {
                setIsErrorPhone({ ...isErrorPhone, state: true })
            }
            if(!validateEmail(formData.email)){
                setIsErrorEmail({ ...isErrorEmail, state: true })
            } 
            if (validateInput(formData.name, 3)) {
                setIsErrorName({ ...isErrorName, state: true })
            }
            if (formData.time==='init') {
                setIsErrorTime({ ...isErrorTime, state: true })
            }
            
            return false         
        }
        
    }


      const sendForm = () =>{   
            axios.post('http://localhost/api/setReservation.php', {
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            comment: formData.comment,
            date: formData.date,
            time: formData.time,
            guest: formData.guest
          })
          .then(function (response) {
             
            console.log(response);
            
          })
          .catch(function (error) {
            console.log(error);
          });

      }
   
  return (
     
    <div className="container parallax pt-1 mt-5 pb-4">
        <main className="form-signin bg-light mt-5">
                <div className="row g-5">
                  <div className="col-12">
                  
                        <h5 className="mb-3 text-center">Vaše rezervace</h5>
                        <div className="row g-3">
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <span className="input-group-text bg-light"><i className="far fa-calendar-alt"></i></span>
                                    <select className="form-select" style={{borderLeft: "none"}} onChange={(e)=>{configureDate(e)}}>
                                    {day.map((singleDay)=>{
                                          return <option key={singleDay.date} value={singleDay.date}>{singleDay.titleCZ}</option>
                                      })}
                                    </select>
                                  </div>
                            </div>
            
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <span className="input-group-text bg-light"><i className="far fa-clock"></i></span>
                                    <select 
                                    className= {isErrorTime.state ? "form-select is-invalid" : "form-select"} 
                                    style={isErrorTime.state ? {}: {borderLeft: "none"}} 
                                    defaultValue={'init'} 
                                    onChange={(e)=>{setIsErrorTime({...isErrorTime, state: false}); setFormData({ ...formData, time: e.target.value })}}>
                                      <option value="init">V kolik hodin?</option>
                                      {time.map((singleTime)=>{
                                          return <option key={singleTime.time}>{singleTime.time}</option>
                                      })}
                                    </select>
                                  </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <span className="input-group-text bg-light"><i className="fas fa-users"></i></span>
                                    <select className="form-select" defaultValue={formData.guest} style={{borderLeft: "none"}} onChange={(e)=>setFormData({ ...formData, guest: e.target.value })}>
                                        <option value="1">1 osoba</option>
                                        <option value="2">2 osoby</option>
                                        <option value="3">3 osoby</option>
                                        <option value="4">4 osoby</option>
                                        <option value="5">5 osob</option>
                                        <option value="6">6 osob</option>
                                        <option value="7">7 osob</option>
                                        <option value="8">8 osob</option>
                                        <option value="9">9 osob</option>
                                        <option value="10">10 osob</option>
                                        <option value="11">Více (napište do poznámky)</option>
                                    </select>
                                  </div>
                            </div>
                
                            
                             
            
                      <hr className="my-4" />
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-light"><i className="fas fa-user"></i></span>
                        <input type="text" 
                        className= {isErrorName.state ? "form-control is-invalid" : "form-control"} 
                        style={isErrorName.state ? {}: {borderLeft: "none"}} 
                        placeholder="Jméno a příjmení" 
                        value={formData.name}
                        onChange={(e) => {
                            if(isErrorName.state){
                                if (!validateInput(formData.name, 2)) {
                                    setIsErrorName({ ...isErrorName, state: false })
                                }    
                            }
                            setFormData({ ...formData, name: e.target.value });
                            }}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-light"><i className="fas fa-at"></i></span>
                        <input type="email" 
                        className= {isErrorEmail.state ? "form-control is-invalid" : "form-control"} 
                        style={isErrorEmail.state ? {}: {borderLeft: "none"}} 
                        placeholder="E-mail"
                        value={formData.email}
                        onChange={(e) => {
                            if(isErrorEmail.state){
                                if(validateEmail(e.target.value)){
                                    setIsErrorEmail({ ...isErrorEmail, state: false })
                                }    
                            }
                            setFormData({ ...formData, email: e.target.value });
                            }}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-light"><i className="fas fa-phone"></i></span>
                        <input type="text" 
                        className= {isErrorPhone.state ? "form-control is-invalid" : "form-control"} 
                        style={isErrorPhone.state ? {}: {borderLeft: "none"}}  
                        placeholder="Telefon"
                        value={formData.phone}
                        onChange={(e) => {
                            if(isErrorPhone.state){
                                if (!validateInput(formData.phone, 8)) {
                                    setIsErrorPhone({ ...isErrorPhone, state: false })
                                }    
                            }
                            setFormData({ ...formData, phone: e.target.value });
                            }}
                        />
                      </div>
                      <div className="form-floating">
                        <textarea className="form-control" id="floatingTextarea2" defaultValue=
                        {formData.comment} style={{height: "70px"}} onChange={(e) => {
                            setFormData({ ...formData, comment: e.target.value });
                            }}>
                        </textarea>
                        <label htmlFor="floatingTextarea2">Poznámka k rezervaci</label>
                      </div>
                      <button className="w-100 btn btn-success btn-lg" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=> {if(validateForm()){sendForm()}}} type="submit">Rezervovat stůl</button>
                      <div className="modal fade" data-bs-backdrop="static" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">  
                        {isSend ?
                        <SuccessModal setFormData={setFormData}/> :
                        <ErrorModal isErrorEmail={isErrorEmail} isErrorName={isErrorName} isErrorPhone={isErrorPhone} isErrorTime={isErrorTime}/>
                        }
                     </div>
                  </div>
                </div>
              </div>
        </main>
    </div>   
    
        


  );
                        }

export default Main;
